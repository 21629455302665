import React, { useState, useEffect } from "react";
import "./Modal.css";
import logo from "../assets/Teldio-Logo-Color.png";
import {
  Button,
  TextField,
  Typography,
  styled,
  Snackbar,
  Alert,
} from "@mui/material";

export const TeldioTextField = styled(TextField)({
  "& .MuiInputLabel-root": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "transparent",
      borderRadius: "15px",
      boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    },
    "&:hover fieldset": {
      borderColor: "#A0AD39",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#A0AD39",
    },
  },
  "& .MuiInputBase-input": {
    color: "black",
    "&::placeholder": {
      color: "darkgrey",
      opacity: 1,
    },
  },
});

export const SquaredButtonGreen = styled(Button)(({ theme }) => ({
  borderRadius: "5px",
  backgroundColor: "#A0AD39",
  "&:hover": {
    backgroundColor: "#c1cd66",
  },
  color: "white",
}));

const Modal = () => {
  const params = new URLSearchParams(window.location.search);
  const showDiv = params.get("tools") !== "true";
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const [zoneData, setZoneData] = useState(null);
  const [mapCenter, setMapCenter] = useState([40.7128, -74.006]); // Default to New York City
  const [markerPosition, setMarkerPosition] = useState([40.7128, -74.006]);

  const isValidLatLong = (lat, long) => {
    const latNum = parseFloat(lat);
    const longNum = parseFloat(long);
    return (
      !isNaN(latNum) &&
      !isNaN(longNum) &&
      latNum >= -90 &&
      latNum <= 90 &&
      longNum >= -180 &&
      longNum <= 180
    );
  };

  const getLocationData = () => {
    if (lat === "" || long === "") {
      setError("You must enter a lat and long");
      return;
    }
    if (!isValidLatLong(lat, long)) {
      setError("Invalid latitude or longitude values");
      return;
    }
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `https://api.weather.gov/zones?point=${lat}%2C${long}&include_geometry=false`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          setError("Invalid response from the API");
          throw new Error("Invalid response from the API");
        }
        return response.json();
      })
      .then((result) => {
        setResponse(result);
        console.log(result);
        if (result.features && result.features.length > 0) {
          setZoneData(result.features[0].properties);
        } else {
          setError("Invalid Location");
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const handleClose = () => {
    setError(null);
  };

  useEffect(() => {
    if (isValidLatLong(lat, long)) {
      setMapCenter([parseFloat(lat), parseFloat(long)]);
      setMarkerPosition([parseFloat(lat), parseFloat(long)]);
    }
  }, [lat, long]);

  return (
    <>
      {showDiv && (
        <div
          style={{
            width: "100%",
            height: "70px",
            backgroundColor: "#424242",
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            fontFamily: "fonts/Oxygen-Light",
          }}
        >
          <Typography
            sx={{
              width: "100%",
              color: "white",
              fontFamily: "inherit",
              textAlign: "center",
            }}
            variant="h4"
          >
            Teldio NOAA Location Tool
          </Typography>
          <img
            src="assets/logo.png"
            alt="teldio logo"
            style={{
              height: "35px",
              marginRight: "15px",
              position: "absolute",
            }}
          />
        </div>
      )}
      <div className="container">
        <div
          style={{
            backgroundColor: "white",
            marginTop: "20px",
            width: "clamp(400px, 60%, 500px)",
            borderRadius: "30px",
            boxShadow: "15px 15px 19px rgba(0, 0, 0, 0.1)",
            padding: "20px 20px 40px 20px",
          }}
        >
          <div className="bannerContainer">
            <img src={logo} alt="Teldio Logo" className="logo" />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "20px",
            }}
          >
            <TeldioTextField
              placeholder="Latitude"
              onChange={(e) => setLat(e.target.value)}
              value={lat}
            />
            <TeldioTextField
              placeholder="Longitude"
              onChange={(e) => setLong(e.target.value)}
              value={long}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  getLocationData();
                }
              }}
            />
          </div>
          <SquaredButtonGreen
            sx={{ marginTop: "20px", width: "200px" }}
            onClick={getLocationData}
          >
            Locate
          </SquaredButtonGreen>
          {zoneData && (
            <div style={{marginTop: "20px"}}>
              <Typography variant="h6">NOAA Information</Typography>
              <Typography>Zone: {zoneData.id}</Typography>
              <Typography>Name: {zoneData.name}</Typography>
              <Typography>State: {zoneData.state}</Typography>
              <Typography>Time Zone: {zoneData.timeZone.join(", ")}</Typography>
            </div>
          )}
        </div>
      </div>

      <Snackbar
        open={error !== null}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Modal;
